import React from 'react';
import Dropzone from 'react-dropzone';
import { i18n } from 'Language';
import DummyDocumentRow from 'Casefiles/components/casefiles/DummyDocumentRow';
import DocumentListItem from 'Casefiles/components/casefiles2/DocumentListItem';
import analytics from 'Common/Analytics';
import {
    DocumentEntity,
    DocumentType,
    UploadingDocument,
} from 'types/Document';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { UploadedDocument } from 'types/CaseFile';
import UIInlineMessage from '../Vega/InlineMessage';
import {
    UploadStatuses,
    UploadStatus,
} from 'Casefiles/components/casefiles2/types';
import { getUploadPDFErrorDescription } from 'Casefiles/components/casefiles2/utils';
import DocumentStore from 'Casefiles/stores/DocumentStore';
import Constants from 'Constants';

type Props = {
    availableDocumentTypes: DocumentType[];
    /**
     * Regular casefile creation process uses DocumentEntity while registered
     * letters uses UploadedDocument
     */
    documents: (File | DocumentEntity | UploadingDocument | UploadedDocument)[];
    onAddFileLinkClick: () => void;
    onChangeDocumentOrder: (index: number, newIndex: number) => void;
    onDrop: (files: File[]) => void;
    onEdit?: (index: number) => void;
    onPreview: (index: number) => void;
    /** Must be provided if onEdit is not provided */
    onRemove?: (index: number) => void;
    onCancelUpload?: (index: number) => void;
};
type ErrorMessageConfig = {
    descriptionKey: string;
    titleKey?: string;
    linkText?: string;
    linkValue?: string;
};
type State = {
    uploadStatuses: UploadStatuses;
};

export default DragDropContext(HTML5Backend)(
    class DocumentDropZone extends React.Component<Props> {
        state: State = {
            uploadStatuses: DocumentStore.getUploadStatuses(),
        };

        componentDidMount() {
            DocumentStore.addChangeListener(this.handleStoreChange);
        }

        componentWillUnmount() {
            DocumentStore.removeChangeListener(this.handleStoreChange);
        }

        handleStoreChange = () => {
            this.setState({
                uploadStatuses: DocumentStore.getUploadStatuses(),
            });
        };

        getErrorConfig(status: UploadStatus): ErrorMessageConfig {
            if (
                typeof status === 'object' &&
                'status' in status &&
                status.status === 'error'
            ) {
                const errorDescription = getUploadPDFErrorDescription(status);

                const config: ErrorMessageConfig = {
                    descriptionKey: errorDescription,
                };

                if (errorDescription === 'timeoutError') {
                    config.titleKey = 'timeoutErrorTitle';
                } else if (errorDescription === 'generalError') {
                    config.linkText = 'Contact Support';
                    config.linkValue = Constants.sites.supportRequest;
                }

                return config;
            }

            return {
                descriptionKey: 'uploadDelayed',
            };
        }

        renderUploadMessage(status: UploadStatus) {
            if (
                status === 'delayed' ||
                (typeof status === 'object' &&
                    'status' in status &&
                    status.status === 'error')
            ) {
                const {
                    descriptionKey,
                    titleKey,
                    linkText,
                    linkValue,
                } = this.getErrorConfig(status);

                return (
                    <div className="mt-1">
                        <UIInlineMessage
                            variant={status === 'delayed' ? 'accent' : 'danger'}
                            description={i18n(
                                `uploadDocument.${descriptionKey}`
                            )}
                            title={
                                titleKey
                                    ? i18n(`uploadDocument.${titleKey}`)
                                    : ''
                            }
                            hideCloseButton={true}
                            linkText={linkText ? i18n(linkText) : ''}
                            linkValue={linkValue}
                        />
                    </div>
                );
            }
        }

        render() {
            const {
                availableDocumentTypes,
                documents,
                onAddFileLinkClick,
                onChangeDocumentOrder,
                onDrop,
                onEdit,
                onPreview,
                onRemove,
            } = this.props;
            const { uploadStatuses } = this.state;

            return (
                <Dropzone
                    accept="application/pdf"
                    className="document-list-v2"
                    disableClick={true}
                    disablePreview={true}
                    onDrop={onDrop}>
                    <ul style={{ overflowY: 'scroll' }}>
                        {documents?.length === 0 && (
                            <div className="document-list-v2-empty">
                                <div className="document-list-empty-graphic">
                                    <i className="far fa-file-alt" />
                                    <i className="far fa-mouse-pointer" />
                                </div>
                                <h2>{i18n`Drag and drop files to upload`}</h2>
                                <p>{i18n`- or -`}</p>
                                <span
                                    className="underline-link"
                                    onClick={onAddFileLinkClick}>
                                    <i className="fas fa-desktop" />
                                    &nbsp;
                                    {i18n`Select files from your computer`}
                                </span>
                            </div>
                        )}

                        {/* This component needs a dummy row that attaches at least one
                            react-dnd source. If react-dnd has no sources initialized, it
                            causes a conflict with react-dropzone. (Invariant Error: Cannot
                            call hover while dragging)

                            i.e: Whenever you drop a file to the area. react-dnd will throw
                            an error because it tries to launch an event without any
                            children that can listen to it. as none of the rows are
                            initialized with the proper event listeners.

                            This is only an issue because every DocumentListItem is both a
                            drop target and a source. And the drop events are caught within
                            react-dropzone initially, which doesn't report the correct state
                            to react-dnd.

                            To read more about this error, read the linked github issues in
                            DummyDocumentRow component. */}
                        <DummyDocumentRow />

                        {documents &&
                            documents.map((file, index) => {
                                const isValidFileType = (
                                    f: any
                                ): f is UploadingDocument | UploadedDocument =>
                                    '_id' in f || 'id' in f;

                                const transformedFile:
                                    | UploadingDocument
                                    | UploadedDocument = isValidFileType(file)
                                    ? file
                                    : {
                                          _id: '',
                                          caseFileId: 0,
                                          documentTypeId: 0,
                                          file:
                                              file instanceof File
                                                  ? file
                                                  : new File([], 'placeholder'),
                                          filename:
                                              file instanceof File
                                                  ? file.name
                                                  : 'placeholder',
                                          name:
                                              file instanceof File
                                                  ? file.name
                                                  : 'placeholder',
                                          order: 0,
                                      };

                                return (
                                    <div>
                                        <DocumentListItem
                                            availableDocumentTypes={
                                                availableDocumentTypes
                                            }
                                            edit={(index) => onEdit?.(index)}
                                            file={transformedFile}
                                            index={index}
                                            key={index}
                                            onChangeDocumentOrder={(
                                                ind: number,
                                                newIndex: number
                                            ) => {
                                                analytics.track(
                                                    'Casefile document reorder'
                                                );
                                                onChangeDocumentOrder(
                                                    ind,
                                                    newIndex
                                                );
                                            }}
                                            preview={onPreview}
                                            remove={(index) =>
                                                onRemove?.(index)
                                            }
                                            onUploadStatus={
                                                '_id' in file &&
                                                typeof file._id === 'string' &&
                                                uploadStatuses
                                                    ? uploadStatuses[file._id]
                                                    : undefined
                                            }
                                        />
                                        {'_id' in file &&
                                            uploadStatuses &&
                                            uploadStatuses[file._id] &&
                                            this.renderUploadMessage(
                                                uploadStatuses[file._id]
                                            )}
                                    </div>
                                );
                            })}
                    </ul>
                </Dropzone>
            );
        }
    }
);
