import React, { useCallback } from 'react';
import { i18n } from 'Language';
import UILoadingSpinner from 'Common/components/Vega/LoadingSpinner';
import Button from 'Common/components/Button';
import { XMarkIcon } from '@heroicons/react/20/solid';
import UITooltip from 'Common/components/Vega/Tooltip';
import { UploadStatus } from './types';
import { DocumentType, UploadingDocument } from 'types/Document';
import DocumentActions from 'Casefiles/actions/DocumentActionCreators';
import { UploadedDocument } from 'types/CaseFile';

interface Props {
    file: UploadingDocument | UploadedDocument;
    uploadStatus: UploadStatus | undefined;
    index: number;
    preview: (index: number) => void;
    edit?: (index: number) => void;
    remove: (index: number) => void;
    isUploadingFile: (file: any) => file is UploadingDocument;
    fetchingDocumentTypes: boolean;
    isDocumentTypeAvailable: false | DocumentType;
    documentType?: { name: string };
}

const DocumentStatusListItem: React.FC<Props> = ({
    file,
    uploadStatus,
    index,
    preview,
    edit,
    remove,
    isUploadingFile,
    fetchingDocumentTypes,
    isDocumentTypeAvailable,
    documentType,
}) => {
    const cancelUpload = useCallback(() => {
        if (isUploadingFile(file) && file._id) {
            DocumentActions.abortDocument(file._id);
        }
    }, [file, isUploadingFile]);

    const renderUploadingState = () => (
        <div className="inline-flex items-center justify-between ml-8">
            <span className="ml-8 mr-4">{i18n`Uploading`}...</span>
            <div className="p-2">
                <UILoadingSpinner variant="accent" />
            </div>
            <Button variant="text" onClick={cancelUpload}>
                <XMarkIcon className="h-6 fill-neutral-700" />
            </Button>
            <UITooltip placement="right" offset={7}>
                {i18n`Remove`}
            </UITooltip>
        </div>
    );

    const renderCompletedState = () => (
        <>
            {file.documentTypeId && (
                <span className="type text-penneo-blue mr-4">
                    {isDocumentTypeAvailable ? (
                        <>
                            <i className="fas fa-tag" />
                            {documentType?.name}
                        </>
                    ) : (
                        <span>
                            {fetchingDocumentTypes ? (
                                <UILoadingSpinner variant="accent" />
                            ) : (
                                i18n`Select a type`
                            )}
                        </span>
                    )}
                </span>
            )}
            <div className="actions-container">
                <i
                    className="far fa-eye text-penneo-cyan mr"
                    onClick={() => preview(index)}
                />
                {edit ? (
                    <i
                        className="far fa-edit text-penneo-cyan"
                        onClick={() => edit(index)}
                    />
                ) : (
                    <i
                        className="far fa-trash text-red"
                        onClick={() => remove(index)}
                    />
                )}
            </div>
        </>
    );

    const renderErrorState = () => (
        <Button variant="text" onClick={cancelUpload}>
            <XMarkIcon className="h-6 fill-neutral-700" />
        </Button>
    );

    if (!isUploadingFile(file) && file.id) {
        return renderCompletedState();
    } else if (uploadStatus === 'uploading' || uploadStatus === 'delayed') {
        return renderUploadingState();
    } else if (
        typeof uploadStatus === 'object' &&
        uploadStatus.status === 'error'
    ) {
        return renderErrorState();
    }

    return null;
};

export default DocumentStatusListItem;
