import React from 'react';

// Common components
import Button from 'Common/components/Button';
import { i18n } from 'Language';

// Recipient components
import RecipientList from './RecipientList';
import RecipientListNoData from './RecipientListNoData/RecipientListNoData';
import { RecipientSigner, RecipientCopy } from 'types/Recipient';
import { RegisteredLetterRecipient } from 'RegisteredLetter/types';

type RecipientsProps = {
    recipients: (RegisteredLetterRecipient | RecipientSigner | RecipientCopy)[];
    header?: React.ReactNode | React.ReactNode[];
    addRecipient?: (
        index?: null | number,
        recipient?: RegisteredLetterRecipient | RecipientSigner | RecipientCopy
    ) => void;
    editRecipient?: (
        index?: number | null,
        recipient?: RegisteredLetterRecipient | RecipientSigner | RecipientCopy
    ) => void;
    registeredLetter?: boolean;
    casefileLanguage?: string;
};

const Recipients = ({
    editRecipient,
    addRecipient,
    header,
    recipients,
    registeredLetter,
    casefileLanguage,
}: RecipientsProps) => (
    <>
        <div className="casefile-process-body-header">
            {header}
            {addRecipient && (
                <Button
                    data-testid={'add-recipient-button'}
                    theme="blue"
                    icon="far fa-plus-circle"
                    variant="text"
                    onClick={() => addRecipient?.(null)}
                    renderIconLeft={true}>
                    {i18n('Add recipient')}
                </Button>
            )}
            {/* Signer Count */}
            <span className="pull-right count">
                {recipients?.length}&nbsp;
                {recipients?.length === 1 ? i18n`Recipient` : i18n`Recipients`}
            </span>
        </div>

        <div className="signer-list-v2">
            <RecipientList
                recipients={recipients}
                className="mt0 mb0"
                onItemClick={editRecipient}
                casefileLanguage={casefileLanguage}
                noData={
                    <RecipientListNoData
                        openRecipientModal={() => addRecipient?.(null)}
                    />
                }
                registeredLetter={registeredLetter}
            />
        </div>
    </>
);

export default Recipients;
