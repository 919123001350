import React, { useEffect } from 'react';
import { UserEntity as User } from '../types/User';
import { i18n } from 'Language';
import Loader from 'Common/components/Common/Loader';
import { SigningAPI } from 'Api';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import { ZENDESK_LANGUAGE_MAP } from 'Language/Constants';

import analytics from 'Common/Analytics';

import './help-center.scss';

type Props = {
    user: User;
    loggedInRedirectUrl?: string;
    nonLoggedInRedirectUrl?: string;
};

const redirectToHelpCenter = async (returnTo: string) => {
    await analytics.track('Help Center - Zendesk non-logged in');

    window.location.href = returnTo;
};

const createAndSubmitForm = (jwt: string, returnTo: string) => {
    const url = 'https://penneosupport.zendesk.com/access/jwt';

    // Create or get the form element
    let form = document.getElementById('zendesk-jwt-form') as HTMLFormElement;

    if (!form) {
        form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', url);
        form.setAttribute('id', 'zendesk-jwt-form');
        document.body.appendChild(form);
    }

    // Create or get the jwt input element
    let jwtInput = document.getElementById('jwt-id-input') as HTMLInputElement;

    if (!jwtInput) {
        jwtInput = document.createElement('input');
        jwtInput.setAttribute('name', 'jwt');
        jwtInput.setAttribute('type', 'hidden');
        jwtInput.setAttribute('id', 'jwt-id-input');
        form.appendChild(jwtInput);
    }

    jwtInput.value = jwt;

    // Create or get the return_to input element
    let returnToInput = document.getElementById(
        'return-to-input'
    ) as HTMLInputElement;

    if (!returnToInput) {
        returnToInput = document.createElement('input');
        returnToInput.setAttribute('name', 'return_to');
        returnToInput.setAttribute('type', 'hidden');
        returnToInput.setAttribute('id', 'return-to-input');
        form.appendChild(returnToInput);
    }

    returnToInput.value = returnTo;

    form.submit();
};

const fetchJwtAndRedirect = async (
    returnToLoggedIn: string,
    returnToNonLoggedIn: string
) => {
    try {
        const response = await SigningAPI.get('/v1/user-help-center/token');

        const jwt = response.jwtToken;

        await analytics.track('Help Center - Zendesk JWT');
        createAndSubmitForm(jwt, returnToLoggedIn);
    } catch (error) {
        console.error('Error while redirecting to help center:', error);
        redirectToHelpCenter(returnToNonLoggedIn);
    }
};

export const HelpCenter = (props: Props) => {
    const { user } = props;

    const language = ZENDESK_LANGUAGE_MAP[user.language] || 'en-gb';

    const {
        loggedInRedirectUrl = `https://www.support.penneo.com/hc/${language}`,
        nonLoggedInRedirectUrl = `https://www.support.penneo.com/hc/${language}`,
    } = props;

    const hasZendeskSSOFlag = launchDarkly.variation(
        Flags.ENABLE_ZENDESK_SSO_LOGIN_HELP_CENTER
    );

    useEffect(() => {
        // read the JWT access token and redirect to the help center
        if (hasZendeskSSOFlag) {
            fetchJwtAndRedirect(loggedInRedirectUrl, nonLoggedInRedirectUrl);
        } else {
            //redirect to the help center
            redirectToHelpCenter(nonLoggedInRedirectUrl);
        }
    }, [loggedInRedirectUrl, nonLoggedInRedirectUrl, hasZendeskSSOFlag]);

    return (
        <div className="redirect-page">
            <div className="content">
                <div key="redirect-container" className="redirect-container">
                    <div className="bar success"></div>
                    <div className="document-stack-icon">
                        <div className="document-image">
                            <i className="fa fa-question-circle"></i>
                        </div>
                        <div className="lines"></div>
                        <div className="page"></div>
                        <div className="page page-2"></div>
                        <div className="page page-3"></div>
                    </div>
                    <h1 style={{ fontSize: '20pt' }}>
                        {i18n`helpCenter.penneoSupport`}
                    </h1>

                    <div>
                        <h2 className="title">
                            {i18n`helpCenter.redirectingToHelpCenter`}
                        </h2>
                        <Loader type="dots" />
                    </div>
                </div>
            </div>
        </div>
    );
};
