import { SigningAPI } from 'Api';
import { ResponseType } from 'axios';
import { debug } from 'Core';
import React from 'react';
import { CaseFileAttribute, CaseFileEntity } from 'types/CaseFile';
import UITooltip from 'Common/components/Vega/Tooltip';
import { UploadError } from './types';

const collapseText = (text: string, limit = 35) => {
    let collapsed = '';
    let length = text.length;

    if (length > limit) {
        collapsed = text.slice(0, 20) + '...' + text.slice(length - 10, length);
    }

    return {
        full: text,
        collapsed: collapsed,
    };
};

export const getColorByIndex = (index: number) => {
    const colors = [
        '#8342FF',
        '#55CA92',
        '#49B0E7',
        '#00025F',
        '#911EB4',
        '#42D4F4',
        '#F032E6',
        '#BFEF45',
        '#FABEBE',
        '#469990',
        '#E6BEFF',
        '#9A6324',
        '#FFFAC8',
        '#800000',
        '#AAFFC3',
        '#808000',
        '#FFD8B1',
        '#000075',
        '#FFA128',
    ];

    return colors?.[index] ?? '#ccc';
};

export const ColorLegendCircle = ({ index, text = null }) => (
    <label aria-hidden={true} className="legend-label">
        <i
            className="legend-label-circle"
            style={{ backgroundColor: getColorByIndex(index) }}></i>
        {text}
    </label>
);

type RenderFileLabelWithTooltipProps = {
    fileName: string;
    fileNameLimit?: number;
    tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
    tooltipOffset?: number;
};

export const renderFileLabelWithTooltip = ({
    fileName,
    fileNameLimit = 35,
    tooltipPlacement = 'top',
    tooltipOffset = 20,
}: RenderFileLabelWithTooltipProps) => {
    const label = collapseText(fileName, fileNameLimit);

    return (
        <>
            <span>{label.collapsed || label.full}</span>
            {label.collapsed && (
                <UITooltip placement={tooltipPlacement} offset={tooltipOffset}>
                    <div className="max-w-xs">{label.full} </div>
                </UITooltip>
            )}
        </>
    );
};

/**
 *
 * @param documentId The id of the PDF document to fetch
 * @returns A blob with the document content
 */
export const fetchDocumentContent = async (
    documentId: number | string
): Promise<Blob> => {
    try {
        let query = {
            signed: false,
        };

        let options = {
            responseType: 'blob' as ResponseType,
            headers: {
                Accept: 'application/pdf',
            },
        };

        return await SigningAPI.get(
            `/documents/${documentId}/content`,
            query,
            options
        );
    } catch (error) {
        debug.error(error);

        throw error;
    }
};

/**
 * Assign a temporary value to sendAt,
 * so casefile won't be queued to be sent while still
 * is technically a draft
 */
const TEMP_SEND_AT = 'casefile/temporary_send_at';

export const createTemporarySendAt = async (
    caseFileId: number,
    sendAt: number
): Promise<void> => {
    try {
        await SigningAPI.post(`/casefiles/${caseFileId}/attributes`, {
            name: TEMP_SEND_AT,
            value: sendAt.toString(),
        });
    } catch (error) {
        console.error(`Failed to set send date`);
    }
};

export const updateTemporarySendAt = async (
    caseFileId: number,
    sendAt: number,
    tempSendAt: CaseFileAttribute
): Promise<void> => {
    /**
     * We compare the current temp value to the new one.
     * If both are the same, we do nothing.
     */
    const newSendAt = sendAt.toString();

    if (newSendAt === tempSendAt.value) {
        return;
    }

    try {
        await SigningAPI.put(
            `/casefiles/${caseFileId}/attributes/${tempSendAt.id}`,
            {
                name: TEMP_SEND_AT,
                value: newSendAt,
            }
        );
    } catch (error) {
        console.error(`Failed to update send date`);
    }
};

export const deleteTemporarySendAt = async (
    caseFileId: number,
    tempSendAtId: CaseFileAttribute['id']
): Promise<void> => {
    try {
        await SigningAPI.delete(
            `/casefiles/${caseFileId}/attributes/${tempSendAtId}`
        );
    } catch (error) {
        console.error(`Failed to delete send date`);
    }
};

export const getTemporarySendAt = async (
    caseFileId: number
): Promise<CaseFileAttribute | null> => {
    let date: CaseFileAttribute | null = null;

    try {
        const casefile: CaseFileEntity = await SigningAPI.get(
            `/casefiles/${caseFileId}/details`
        );

        /**
         * Attributes could be undefined or not an array,
         * so we make sure it exists and it's iterable
         */
        if (Array.isArray(casefile.attributes)) {
            const tempDate = casefile.attributes.find(
                (attr) => attr.name === TEMP_SEND_AT
            );

            if (tempDate) {
                date = tempDate;
            }
        }
    } catch (error) {
        console.error(`Failed to retrieve send date`);
    } finally {
        return date;
    }
};

export const getUploadPDFErrorDescription = (error: UploadError): string => {
    if (error.translationKey) {
        return error.translationKey;
    } else if (error.code === 404 || error.code === 504 || error.code === 413) {
        return 'timeoutError';
    } else {
        return 'generalError';
    }
};

export const getUploadError = (error): UploadError => {
    return {
        status: 'error',
        code: error.status,
        message: error.data?.errorString || error.message,
        translationKey: error.data?.translationKey,
        details: error.data,
    };
};
